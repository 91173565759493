<template>
  <div id="dashboard-view" class="tw-overflow-auto">
    <MobileSidebar />
    <Sidebar />
    <div class="view-container">
      <Header />
      <div class="tw-pt-32 tw-min-h-screen tw-bg-gray-lightest">
        <!-- <Loader v-if="loading" /> -->
        <router-view v-slot="{ Component }">
          <transition name="fade-transform" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Header from "@/layout/navigation/Header.vue";
  import Sidebar from "@/layout/navigation/Sidebar.vue";
  import MobileSidebar from "@/layout/navigation/MobileSidebar.vue";
  import { useStore } from "vuex";
  import { computed } from "vue";

  const store = useStore();

  const loading = computed(() => store.getters["loading"]);
</script>

<style lang="scss" scoped>
  #dashboard-view {
    @include fadeIn;
    height: 100%;
    display: flex;

    .view-container {
      width: 100%;

      @media screen and (min-width: 1280px) {
        width: calc(100% - #{$side-bar-width});
      }
    }
  }
</style>
