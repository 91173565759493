<template>
  <div
    id="header-profile"
    class="tw-retlative tw-flex tw-items-center"
    v-if="orgDetails"
  >
    <div class="tw-relative">
      <img
        v-if="orgDetails.image"
        src="@/assets/img/notification-bell.svg"
        class="tw-w-5 tw-h-5 tw-mr-10 tw-cursor-pointer"
        alt="notification"
        @click="openNotification"
      />
      <div
        class="notification-container tw-absolute tw--top-3 tw-left-2.5 tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-bg-error tw-text-white tw-cursor-pointer"
        @click="openNotification"
        v-if="notificationCount"
      >
        <p class="tw-text-[10px]">
          {{
            notificationCount
              ? notificationCount <= 20
                ? notificationCount
                : "20+"
              : 0
          }}
        </p>
      </div>
    </div>
    <NotificationModal
      v-if="showNotification"
      title="Notifications"
      :showBackButton="false"
      :bgChange="true"
      @close="showNotification = !showNotification"
    >
      <template v-slot:content>
        <div v-if="showNotification" ref="notification">
          <div
            v-if="!loading"
            class="scrollbar tw-h-screen tw-overflow-y-scroll tw-pb-10 tw-px-8"
          >
            <div
              v-for="(group, index) in notifications"
              :key="'notification' + index"
            >
              <p class="tw-text-black-light tw-text-sm tw-mt-8 tw-mb-1">
                {{ formatToStringDate(group[0].createdAt) }}
              </p>
              <div
                class="tw-border tw-rounded tw-shadow tw-px-8 tw-py-4 tw-mb-4"
                :class="{
                  ' tw-cursor-pointer': notification.type === 'Esop Invitation',
                  ' tw-cursor-pointer': notification.type === 'Gift',
                }"
                v-for="(notification, index) in group"
                :key="'notification' + index"
                @click="showInvitiation(notification)"
              >
                <div class="tw-w-full">
                  <div>
                    <div class="tw-flex tw-justify-between tw-items-center">
                      <h3 class="tw-text-sm tw-font-bold tw-mb-2">
                        {{ removeUnderscore(notification.type) }}
                      </h3>
                      <div
                        v-if="
                          notification.raw?.owner_image ||
                            notification.raw?.token_image
                        "
                      >
                        <img
                          :src="
                            notification.raw?.owner_image
                              ? notification.raw?.owner_image
                              : notification.raw?.token_image
                          "
                          class="tw-w-10"
                          alt="notification icon"
                        />
                      </div>
                    </div>
                    <span
                      v-if="notification.read === false"
                      class="new-tag tw-rounded-xl tw-px-2 tw-py-1 tw-mr-2"
                      >New</span
                    ><span
                      class="tw-text-black-light tw-leading-6 tw-text-black tw-text-sm"
                    >
                      {{ notification.message }}
                    </span>
                  </div>
                </div>
                <div class="tw-text-sm tw-mt-2">
                  <div
                    v-if="
                      notification.type === 'Sell' ||
                        notification.type === 'Buy'
                    "
                    class="tw-flex tw-justify-between tw-items-center"
                  >
                    <div>
                      <img
                        src="@/assets/img/token-icon.svg"
                        class="tw-inline-block"
                        alt="show password icon"
                      />
                      <span class="tw-text-black-light tw-mx-1">
                        {{ notification.raw.amount.toLocaleString() }}
                      </span>
                      &#8226;
                      <span class="tw-text-black-light tw-ml-1">
                        {{
                          formatAmountToDollar(
                            notification.raw.price,
                            0,
                            notification.raw.currency ||"USD"
                          )
                        }}/{{ notification.raw.token_symbol }}
                      </span>
                    </div>
                    <div>
                      {{
                        formatAmountToDollar(
                          notification.raw.price * notification.raw.amount,
                          0,
                          notification.raw.currency ||"USD"
                        )
                      }}
                    </div>
                  </div>
                  <p
                    v-if="
                      notification.type === 'Esop Invitation' ||
                        notification.type === 'Invitation'
                    "
                    :class="{
                      'tw-text-primary tw-underline':
                        notification.raw.status === 'Pending',
                      'tw-text-success': notification.raw.status === 'Accepted',
                      'tw-text-error': notification.raw.status === 'Terminated',
                    }"
                  >
                    <span
                      v-if="
                        notification.raw.status !== 'Accepted' &&
                          notification.raw.status !== 'Terminated'
                      "
                      class="tw-cursor-pointer"
                      >Review</span
                    >
                    <span v-if="notification.raw.status === 'Accepted'"
                      >Accepted</span
                    >
                    <span v-if="notification.raw.status === 'Terminated'"
                      >Terminated</span
                    >
                  </p>
                  <p class="tw-text-black-light tw-text-sm tw-mt-6">
                    {{ formatISOTime(notification.createdAt) }}
                  </p>
                </div>
              </div>
            </div>
            <div ref="notificationBottom">
              <div v-if="loadingMore" class="tw-relative tw-h-20">
                <SmallLoader />
              </div>
            </div>
            <div
              v-if="!loadingMore && notifications.length !== 0"
              class="tw-text-lg tw-text-center tw-mt-8"
            >
              <p>End of notification</p>
            </div>
            <div
              v-if="notifications.length === 0"
              class="tw-text-center tw-mt-8"
            >
              <EmptyState>
                <template v-slot:message>
                  <p class="message tw-text-center tw-text-gray tw-my-3">
                    You have zero notifications.
                  </p>
                </template>
              </EmptyState>
            </div>
          </div>
          <div v-if="loading" class="tw-relative tw-h-80">
            <SmallLoader />
          </div>
        </div>
      </template>
    </NotificationModal>
    <!-- <div
      v-if="showNotification"
      class="notification-dropdown tw-absolute tw-rounded tw-top-20 tw--right-0 tw-bg-white tw-w-screen sm:tw-w-96 sm:tw-right-0 tw-text-sm"
      @mouseleave="hideNotification"
      ref="notification"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <h3 class="tw-px-4 tw-text-lg tw-font-bold tw-bg-white tw-pt-4">
          Notifications
        </h3>
        <img
          src="@/assets/img/close-dark.svg"
          class="tw-w-5 tw-h-5 tw-mr-10 tw-cursor-pointer"
          alt="close"
          @click="showNotification = !showNotification"
        />
      </div>
      <div v-if="!loading" class="tw-h-80 tw-overflow-scroll tw-pb-10 tw-mb-10">
        <div
          class="tw-flex tw-justify-between tw-items-center tw-border-t tw-rounded tw-bg-white tw-px-8 tw-py-4 tw-mt-3"
          v-for="(notification, index) in notifications"
          :key="'notification' + index"
        >
          <div class="">
            <p class="tw-text-black-light">
              <span
                v-if="!notification.read"
                class="new-tag tw-rounded-xl tw-px-2 tw-py-1 tw-mr-2"
                >New</span
              ><span class="tw-leading-6 tw-text-black tw-text-base">{{
                notification.message
              }}</span>
            </p>
            <p class="tw-text-sm tw-text-black-light tw-mt-2">
              <span
                v-if="calculateDay(notification.updatedAt) >= 1"
                class="tw-mr-1"
              >
                {{ calculateDay(notification.updatedAt) }} days
              </span>
              <span
                v-if="
                  calculateHour(notification.updatedAt) >= 1 &&
                    calculateDay(notification.updatedAt) < 1
                "
                class="tw-mr-1"
              >
                {{ calculateHour(notification.updatedAt) }} hr
              </span>
              <span
                v-if="
                  calculateMin(notification.updatedAt) < 59 &&
                    calculateHour(notification.updatedAt) < 1 &&
                    calculateDay(notification.updatedAt) < 1
                "
              >
                {{ calculateMin(notification.updatedAt) }} min
              </span>
              ago
            </p>
          </div>
        </div>
        <div ref="notificationBottom">
          <div v-if="loadingMore" class="loading tw-relative tw-h-20">
            <Loader />
          </div>
        </div>
        <div
          v-if="!loadingMore && notifications.length !== 0"
          class="tw-text-lg tw-text-center tw-mt-8"
        >
          <p>End of notification</p>
        </div>
        <div v-if="notifications.length === 0" class="tw-text-center tw-mt-8">
          <EmptyState>
            <template v-slot:message>
              <p class="message tw-text-center tw-text-gray tw-my-3">
                You have zero notifications.
              </p>
            </template>
          </EmptyState>
        </div>
      </div>
      <div v-if="loading" class="loading tw-relative tw-h-80">
        <Loader />
      </div>
    </div> -->
    <div
      class="dropdown tw-relative tw-flex tw-flex-col tw-items-center tw-bg-gray-lightest tw-px-4 tw-py-2 tw-rounded tw-cursor-pointer"
    >
      <div class="tw-flex tw-items-center">
        <img
          v-if="orgDetails.image"
          class="tw-h-8 tw-w-8 tw-rounded-full tw-mr-2"
          :src="orgDetails.image"
          alt="profile"
          loading="lazy"
        />
        <img
          v-else
          class="tw-h-8 tw-mr-2"
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1634012152/getequityV2/header-profile-img_kjtkcq.webp"
          alt="profile"
          loading="lazy"
        />
        <div class="syndicate-name tw-mr-4">
          <h3 class="tw-text-sm tw-hidden md:tw-inline-block">
            {{ truncate(orgDetails?.name, 12) }}
          </h3>
          <h3 class="tw-text-sm md:tw-hidden">
            {{ abbreviate(orgDetails?.name) }}
          </h3>
          <p class="tw-text-xs tw-text-gray-bg1">Admin</p>
        </div>
        <svg
          width="8"
          height="4"
          viewBox="0 0 8 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.00011 2.58602L6.47512 0.111023L7.18212 0.818023L4.00011 4.00002L0.818115 0.818023L1.52512 0.111023L4.00011 2.58602Z"
            fill="#8791A7"
          />
        </svg>
      </div>
      <div
        class="menu tw-absolute tw-hidden tw-rounded tw-top-12 tw-bg-gray-lightest tw-w-full tw-py-4"
      >
        <ul class="tw-px-4">
          <li
            class="list tw-cursor-pointer tw-rounded tw-px-4 tw-py-1"
            @click="logout"
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import NotificationModal from "@/components/general/sidemodal/SideModal.vue";
import EmptyState from "@/components/notification/EmptyState.vue";
import {
  abbreviate,
  calculateMin,
  capitalizeEachWord,
  formatAmountToDollar,
  formatISODate,
  formatISOTime,
  formatToStringDate,
  removeUnderscore,
  truncate,
} from "@/utils/helpers.js";
import { getItem } from "@/utils/storage";
import { mapActions, mapState } from "vuex";
  export default {
    name: "HeaderProfile",

    components: { EmptyState, NotificationModal },

    data() {
      return {
        loading: false,
        loadingMore: false,
        page: 1,
        limit: 10,
        notifications: [],
        page: 1,
        pages: 0,
        syndicateName: "",
        showNotification: false,
      };
    },

    computed: {
      ...mapState({
        orgDetails: (state) => state.organisationModule.organisationDetails,
        notificationCount: (state) =>
          state.organisationModule.notificationCount,
      }),
    },

    created() {
      this.setDetails();
    },

    watch: {
      showNotification(val) {
        if (!val) {
          this.getTotalUnreadNotifications();
        }
      },
    },

    methods: {
      ...mapActions("organisationModule", [
        "setOrganisationProfile",
        "getNotifications",
        "getTotalUnreadNotifications",
      ]),
      ...mapActions(["logout"]),

      capitalizeEachWord,
      truncate,
      abbreviate,
      formatISODate,
      formatISOTime,
      calculateMin,
      formatToStringDate,
      formatAmountToDollar,
      removeUnderscore,

      setDetails() {
        const details = getItem("org_details");
        this.setOrganisationProfile(details);
        this.getTotalUnreadNotifications();
      },

      openNotification() {
        this.page = 1;
        this.notifications = {};
        this.showNotification = true;
        this.loading = true;
        this.fetchNotifications();
      },

      // async fetchNotifications() {
      //   const response = await this.getNotifications({
      //     page: this.page,
      //     limit: this.limit,
      //   });
      //   if (response) {
      //     const { page, pages, notifications } = response.data.data;
      //     this.notifications = this.notifications.concat(notifications);
      //     this.page = page;
      //     this.pages = pages;
      //     setTimeout(() => {
      //       this.setObserver();
      //     }, 100);
      //   }
      //   if (this.loading) this.loading = false;
      //   if (this.loadingMore) this.loadingMore = false;
      // },
      async fetchNotifications() {
        const response = await this.getNotifications({
          page: this.page,
          limit: this.limit,
        });
        if (response) {
          let dates = [];
          let group = {};
          const { page, pages, notifications } = response.data.data;
          notifications.map((notification, index) => {
            if (!dates.includes(notification.createdAt.split("T")[0])) {
              let date = notification.createdAt.split("T")[0];
              dates.push(notification.createdAt.split("T")[0]);
              let groupArray = notifications.filter(
                (noti) => date === noti.createdAt.split("T")[0]
              );
              group[date] = groupArray;
            }
          });
          this.notifications = { ...this.notifications, ...group };
          this.page = page;
          this.pages = pages;
          setTimeout(() => {
            this.setObserver();
          }, 100);
        }
        if (this.loading) this.loading = false;
        if (this.loadingMore) this.loadingMore = false;
      },

      setObserver() {
        const options = {
          root: this.$refs.notification,
          rootMargin: "0px",
          threshold: 0.5,
        };
        const notificationBottom = this.$refs.notificationBottom;
        const observer = new IntersectionObserver(this.callback, options);
        observer.observe(notificationBottom);
      },

      callback(entries, observer) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0 && this.page < this.pages) {
            this.loadingMore = true;
            this.page += 1;
            this.fetchNotifications();
          }
        });
      },

      // setObserver() {
      //   const options = {
      //     root: this.$refs.notification,
      //     rootMargin: "0px",
      //     threshold: 0.5,
      //   };
      //   const notificationBottom = this.$refs.notificationBottom;
      //   const observer = new IntersectionObserver(this.callback, options);
      //   observer.observe(notificationBottom);
      // },

      // callback(entries, observer) {
      //   entries.forEach((entry) => {
      //     if (entry.intersectionRatio > 0 && this.page < this.pages) {
      //       this.loadingMore = true;
      //       this.page += 1;
      //       this.fetchNotifications();
      //     }
      //   });
      // },

      hideNotification() {
        this.timeout = setTimeout(() => {
          this.showNotification = false;
        }, 2000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #header-profile {
    .notification-container {
      border-radius: 50%;

      .notification-count {
        left: 50%;
        transform: translate(-50%, -0%);
      }
    }

    .scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .notification-dropdown {
      .new-tag {
        color: $success;
        background-color: $success-light;
        max-width: 5.4rem;
      }

      .loading {
        @media screen and (min-width: 1280px) {
          margin-right: 20%;
        }
      }
    }

    .dropdown {
      .syndicate-name {
        @media screen and (min-width: 768px) {
          min-width: 5rem;
        }
      }

      .menu {
        .list {
          transition: 0.3s ease all;

          &:hover {
            background-color: #375cb1;
            color: #ffffff;
          }
        }
      }

      &:hover .menu {
        display: block;
      }
    }
  }
</style>
