<template>
  <ul id="sidebar-menu">
    <li>
      <router-link
        :to="href"
        class="tw-flex tw-py-5 tw-cursor-pointer hover:tw-text-primary tw-pl-8"
        :class="[focus === true ? 'tw-text-primary' : 'tw-text-gray']"
      >
        <svg
          v-if="title === 'Overview'"
          class="svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z"
            :fill="$route.path.includes('overview') ? '#4D84FF' : '#8791A7'"
          />
        </svg>
        <svg
          v-if="title === 'Deals'"
          class="svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 0V16H18V18H0V0H2ZM16.94 2.94L19.06 5.06L13 11.122L10 8.122L6.06 12.062L3.94 9.94L10 3.88L13 6.88L16.94 2.94Z"
            :fill="$route.path.includes('deals') ? '#4D84FF' : '#8791A7'"
          />
        </svg>
        <svg
          v-if="title === 'SPVs'"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_915_5019)">
            <path
              d="M3.10547 15V3C3.10547 2.73478 3.21083 2.48043 3.39836 2.29289C3.5859 2.10536 3.84025 2 4.10547 2H20.1055C20.3707 2 20.625 2.10536 20.8126 2.29289C21.0001 2.48043 21.1055 2.73478 21.1055 3V19C21.1055 19.7956 20.7894 20.5587 20.2268 21.1213C19.6642 21.6839 18.9011 22 18.1055 22H4.10547C3.30982 22 2.54676 21.6839 1.98415 21.1213C1.42154 20.5587 1.10547 19.7956 1.10547 19V17H17.1055V19C17.1055 19.2652 17.2108 19.5196 17.3984 19.7071C17.5859 19.8946 17.8403 20 18.1055 20C18.3707 20 18.625 19.8946 18.8126 19.7071C19.0001 19.5196 19.1055 19.2652 19.1055 19V15H3.10547Z"
              :fill="$route.path.includes('spv') ? '#4D84FF' : '#8791A7'"
            />
          </g>
          <defs>
            <clipPath id="clip0_915_5019">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.105469)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          v-if="title === 'Invest'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2082_1793)">
            <path
              d="M3.89701 17.86L7.80701 13.95L10.636 16.778L15.207 12.208L17 14V9H12L13.793 10.793L10.636 13.95L7.80801 11.121L2.86201 16.067C2.29194 14.787 1.99821 13.4012 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.418 22.001 8.85839 21.6262 7.44962 20.9065C6.04084 20.1867 4.82321 19.1425 3.89701 17.86Z"
              :fill="
                $route.path.includes('trade') || $route.path.includes('invest')
                  ? '#4D84FF'
                  : '#8791A7'
              "
            />
          </g>
          <defs>
            <clipPath id="clip0_2082_1793">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="title === 'Wallet'"
          class="svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V6ZM1 0H16V4H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM13 11V13H16V11H13Z"
            :fill="$route.path.includes('wallet') ? '#4D84FF' : '#8791A7'"
          />
        </svg>
        <svg
          v-if="title === 'Members'"
          class="svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 9C11.3261 9 12.5979 9.52678 13.5355 10.4645C14.4732 11.4021 15 12.6739 15 14V20H5V14C5 12.6739 5.52678 11.4021 6.46447 10.4645C7.40215 9.52678 8.67392 9 10 9ZM3.288 12.006C3.12886 12.5428 3.03485 13.0968 3.008 13.656L3 14V20H9.13169e-08V15.5C-0.000196807 14.6376 0.318028 13.8054 0.893635 13.1632C1.46924 12.521 2.2617 12.1139 3.119 12.02L3.289 12.006H3.288ZM16.712 12.006C17.6019 12.0602 18.4376 12.452 19.0486 13.1012C19.6596 13.7505 19.9999 14.6084 20 15.5V20H17V14C17 13.307 16.9 12.638 16.712 12.006ZM3.5 6C4.16304 6 4.79893 6.26339 5.26777 6.73223C5.73661 7.20107 6 7.83696 6 8.5C6 9.16304 5.73661 9.79893 5.26777 10.2678C4.79893 10.7366 4.16304 11 3.5 11C2.83696 11 2.20107 10.7366 1.73223 10.2678C1.26339 9.79893 1 9.16304 1 8.5C1 7.83696 1.26339 7.20107 1.73223 6.73223C2.20107 6.26339 2.83696 6 3.5 6ZM16.5 6C17.163 6 17.7989 6.26339 18.2678 6.73223C18.7366 7.20107 19 7.83696 19 8.5C19 9.16304 18.7366 9.79893 18.2678 10.2678C17.7989 10.7366 17.163 11 16.5 11C15.837 11 15.2011 10.7366 14.7322 10.2678C14.2634 9.79893 14 9.16304 14 8.5C14 7.83696 14.2634 7.20107 14.7322 6.73223C15.2011 6.26339 15.837 6 16.5 6ZM10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4C14 5.06087 13.5786 6.07828 12.8284 6.82843C12.0783 7.57857 11.0609 8 10 8C8.93913 8 7.92172 7.57857 7.17157 6.82843C6.42143 6.07828 6 5.06087 6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0Z"
            :fill="$route.path.includes('members') ? '#4D84FF' : '#8791A7'"
          />
        </svg>
        <svg
          v-if="title === 'Account'"
          class="svg"
          width="19"
          height="22"
          viewBox="0 0 19 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 13V21H0C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM10.595 17.812C10.4682 17.2784 10.4682 16.7226 10.595 16.189L9.603 15.616L10.603 13.884L11.595 14.457C11.9932 14.0799 12.4745 13.8017 13 13.645V12.5H15V13.645C15.532 13.803 16.012 14.085 16.405 14.457L17.397 13.884L18.397 15.616L17.405 16.189C17.5316 16.7222 17.5316 17.2778 17.405 17.811L18.397 18.384L17.397 20.116L16.405 19.543C16.0068 19.9201 15.5255 20.1983 15 20.355V21.5H13V20.355C12.4745 20.1983 11.9932 19.9201 11.595 19.543L10.603 20.116L9.603 18.384L10.595 17.812ZM14 16C13.7348 16 13.4804 16.1054 13.2929 16.2929C13.1054 16.4804 13 16.7348 13 17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17C15 16.7348 14.8946 16.4804 14.7071 16.2929C14.5196 16.1054 14.2652 16 14 16Z"
            :fill="$route.path.includes('account') ? '#4D84FF' : '#8791A7'"
          />
        </svg>
        <svg
          v-if="title === 'API'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_8617_3303)">
            <path
              d="M1.01434 13.813L2.58722 13.8228C2.82242 15.0331 3.28792 16.2091 3.99351 17.2772L2.87632 18.3846C2.47453 18.7815 2.47453 19.4234 2.86652 19.8252L4.07191 21.0453C4.4688 21.4471 5.11069 21.4471 5.51249 21.0551L6.62968 19.9477C7.68806 20.6631 8.85915 21.1482 10.0694 21.3932L10.0596 22.9661C10.0547 23.5296 10.5104 23.9853 11.0739 23.9902L12.7889 24C13.3524 24.0049 13.8081 23.5492 13.813 22.9857L13.8228 21.4128C15.0331 21.1776 16.2091 20.7121 17.2773 20.0065L18.3846 21.1237C18.7815 21.5255 19.4234 21.5255 19.8252 21.1335L21.0453 19.9281C21.4471 19.5312 21.4471 18.8893 21.0551 18.4875L19.9477 17.3703C20.6631 16.3119 21.1482 15.1409 21.3932 13.9306L22.9661 13.9404C23.5296 13.9453 23.9853 13.4896 23.9902 12.9261L24 11.2111C24.0049 10.6476 23.5492 10.1919 22.9857 10.187L21.4128 10.1772C21.1776 8.96694 20.7121 7.79095 20.0065 6.72276L21.1237 5.61537C21.5255 5.21848 21.5255 4.57659 21.1335 4.17479L19.9281 2.9547C19.5312 2.55291 18.8893 2.55291 18.4875 2.9449L17.3704 4.05229C16.312 3.3369 15.1409 2.85181 13.9306 2.60681L13.9404 1.03393C13.9453 0.470434 13.4896 0.014739 12.9261 0.00983903L11.2111 3.91485e-05C10.6476 -0.00486079 10.1919 0.450834 10.187 1.01433L10.1723 2.58721C8.95715 2.82241 7.78116 3.2879 6.71788 3.99349L5.61049 2.87631C5.21359 2.47451 4.5717 2.47451 4.16991 2.86651L2.95472 4.07189C2.55292 4.46879 2.55292 5.11068 2.94492 5.51248L4.05231 6.62966C3.33692 7.68805 2.85182 8.85914 2.60682 10.0694L1.03394 10.0596C0.470449 10.0547 0.0147542 10.5104 0.00985429 11.0739L5.44073e-05 12.7889C-0.00484554 13.3475 0.450849 13.8081 1.01434 13.813ZM8.78565 8.75134C10.579 6.97756 13.4651 6.99226 15.2389 8.78564C17.0127 10.579 16.998 13.4651 15.2046 15.2389C13.4112 17.0126 10.5251 16.9979 8.75135 15.2046C6.97757 13.4112 6.99227 10.5251 8.78565 8.75134Z"
              :fill="$route.path.includes('api') ? '#4D84FF' : '#8791A7'"
            />
          </g>
          <defs>
            <clipPath id="clip0_8617_3303">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span class="tw-pl-5">
          {{ title }}
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "SidebarMenu",

    props: {
      title: { type: String, default: () => "", required: true },
      href: { type: String, default: () => "", required: true },
      focus: { type: Boolean, default: () => false },
    },
  };
</script>

<style lang="scss" scoped></style>
