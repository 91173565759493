<template>
  <div
    id="sidebar"
    class="tw-h-screen tw-bg-white tw-hidden xl:tw-inline-block tw-z-10"
  >
    <div
      class="sidebar-container tw-fixed tw-h-screen tw-overflow-y-scroll tw-pb-16 tw-bg-white"
    >
      <img
        src="@/assets/img/logo-icon.svg"
        class="tw-m-auto tw-pt-7 tw-pb-32 tw-cursor-pointer"
        alt="getEquity"
      />
      <nav>
        <SidebarMenu
          title="Overview"
          href="/dashboard/overview"
          :focus="overviewFocus || $route.path.includes('/dashboard/overview')"
        />
        <SidebarMenu
          title="Deals"
          href="/dashboard/deals"
          :focus="dealsFocus || $route.path.includes('/dashboard/deals')"
        />
        <SidebarMenu
          title="SPVs"
          href="/dashboard/spv"
          :focus="spvFocus || $route.path.includes('/dashboard/spv')"
        />
        <SidebarMenu
          title="Invest"
          href="/dashboard/invest"
          :focus="investFocus || $route.path.includes('/dashboard/invest')"
        />
        <SidebarMenu
          title="Wallet"
          href="/dashboard/wallet"
          :focus="walletFocus || $route.path.includes('/dashboard/wallets')"
        />
        <SidebarMenu
          title="Members"
          href="/dashboard/members"
          :focus="membersFocus || $route.path.includes('/dashboard/members')"
        />
        <SidebarMenu
          title="Account"
          href="/dashboard/account"
          :focus="accountFocus || $route.path.includes('/dashboard/account')"
        />
        <SidebarMenu
          title="API"
          href="/dashboard/api-client"
          :focus="apiFocus || $route.path.includes('/dashboard/api-client')"
        />
      </nav>
      <!-- <router-link
        to="/faqs"
        class="faq-btn tw-absolute tw-bottom-8 tw-m-auto tw-cursor-pointer"
      >
        <img src="@/assets/img/question-mark.svg" alt="question mark" />
      </router-link> -->
    </div>
  </div>
</template>

<script>
  import SidebarMenu from "@/components/navigation/SidebarMenu.vue";
  import { getItem } from "@/utils/storage";
  export default {
    name: "Sidebar",

    components: { SidebarMenu },

    data() {
      return {
        overviewFocus: false,
        dealsFocus: false,
        spvFocus: false,
        walletFocus: false,
        membersFocus: false,
        accountFocus: false,
        apiFocus: false,
        investFocus: false,
      };
    },

    created() {
      this.setFocus(this.$route.name);
    },

    methods: {
      homePage() {
        this.$router.push("/");
      },

      faqPage() {
        this.$router.push("/faqs");
      },

      setFocus(route) {
        this.overviewFocus = false;
        this.dealsFocus = false;
        this.spvFocus = false;
        this.walletFocus = false;
        this.membersFocus = false;
        this.accountFocus = false;
        this.investFocus = false;
        this.apiFocus = false;
        switch (route) {
          case "OverviewDetails":
            this.overviewFocus = true;
            break;
          case "Deals":
            this.dealsFocus = true;
            break;
          case "DealInfo":
            this.dealsFocus = true;
            break;
          case "CreateDeal":
            this.dealsFocus = true;
            break;
          case "CreateAsset":
            this.dealsFocus = true;
            break;
          case "SPVs":
            this.spvFocus = true;
            break;
          case "Invest":
            this.investFocus = true;
            break;
          case "Trade":
            this.investFocus = true;
            break;
          case "TokenTrade":
            this.investFocus = true;
            break;
          case "InvestOrderbook":
            this.investFocus = true;
            break;
          case "Wallet":
            this.walletFocus = true;
            break;
          case "Members":
            this.membersFocus = true;
            break;
          case "Account":
            this.accountFocus = true;
            break;
          case "ApiClient":
            this.apiFocus = true;
            break;
          case "ResetPassword":
            this.accountFocus = true;
            break;

          default:
            break;
        }
      },
    },

    watch: {
      $route(to, from) {
        deep: true;
        immediate: true;
        if (to !== from) {
          this.setFocus(this.$route.name);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #sidebar {
    width: $side-bar-width;

    .sidebar-container {
      width: $side-bar-width;

      .faq-btn {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
</style>
