<template>
  <div id="mobile-sidebar" class="tw-fixed xl:tw-hidden tw-z-30">
    <nav class="navbar">
      <div class="navbar-container">
        <input
          class="tw-fixed tw-top-8 tw-left-5 tw-opacity-0 tw-w-7 tw-h-5 tw-cursor-pointer tw-z-40"
          type="checkbox"
          name="mobileSidebar"
          id="mobile-sidebar"
          ref="mobileSidebar"
        />
        <div
          class="hamburger-lines tw-fixed tw-top-8 tw-left-5 tw-flex tw-flex-col tw-w-7 tw-h-5 tw-justify-between tw-z-20"
        >
          <span class="line1 tw-h-1 tw-rounded tw-bg-gray"></span>
          <span class="line2 tw-h-1 tw-rounded tw-bg-gray"></span>
          <span class="line3 tw-h-1 tw-rounded tw-bg-gray"></span>
        </div>
        <div
          class="menu-items tw-absolute tw-h-screen tw-w-80 tw-bg-white tw-pt-28 tw-overflow-scroll tw-z-10"
        >
          <img
            src="@/assets/img/logo-icon.svg"
            class="tw-absolute tw-top-4 tw-right-10 tw-mb-6 tw-cursor-pointer"
            alt="getEquity-logo"
          />
          <SidebarMenu
            title="Overview"
            href="/dashboard/overview"
            :focus="
              overviewFocus || $route.path.includes('/dashboard/overview')
            "
            @click="closeSidebar"
          />
          <SidebarMenu
            title="Deals"
            href="/dashboard/deals"
            :focus="dealsFocus || $route.path.includes('/dashboard/deals')"
            @click="closeSidebar"
          />
          <SidebarMenu
            title="SPVs"
            href="/dashboard/spv"
            :focus="spvFocus || $route.path.includes('/dashboard/spv')"
            @click="closeSidebar"
          />
          <!-- <SidebarMenu
            title="Invest"
            href="/dashboard/invest"
            :focus="
              investFocus ||
                $route.path.includes('/dashboard/trade') ||
                $route.path.includes('/dashboard/invest')
            "
            @click="closeSidebar"
          /> -->
          <SidebarMenu
            title="Wallet"
            href="/dashboard/wallet"
            :focus="walletFocus || $route.path.includes('/dashboard/wallets')"
            @click="closeSidebar"
          />
          <SidebarMenu
            title="Members"
            href="/dashboard/members"
            :focus="membersFocus || $route.path.includes('/dashboard/members')"
            @click="closeSidebar"
          />

          <SidebarMenu
            title="Account"
            href="/dashboard/account"
            :focus="accountFocus || $route.path.includes('/dashboard/account')"
            @click="closeSidebar"
          />
          <SidebarMenu
            title="API"
            href="/dashboard/api-client"
            :focus="apiFocus || $route.path.includes('/dashboard/api-client')"
          />
          <!-- <img
            src="@/assets/img/question-mark.svg"
            class="faq-btn tw-absolute tw-bottom-20 tw-m-auto tw-cursor-pointer"
            alt="faq"
            @click="faqPage"
          /> -->
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import SidebarMenu from "@/components/navigation/SidebarMenu.vue";
  import { getItem } from "@/utils/storage";

  export default {
    name: "MobileSidebar",

    components: { SidebarMenu },

    data() {
      return {
        overviewFocus: false,
        dealsFocus: false,
        spvFocus: false,
        walletFocus: false,
        membersFocus: false,
        accountFocus: false,
        investFocus: false,
        apiFocus: false,
      };
    },

    created() {
      this.setFocus(this.$route.name);
    },

    computed: {
      accountType() {
        const orgInfo = getItem("org_details");
        return orgInfo.type;
      },
    },

    methods: {
      homePage() {
        this.$router.push("/");
      },

      faqPage() {
        this.$router.push("/faqs");
      },

      closeSidebar() {
        this.$refs.mobileSidebar.checked = false;
      },

      setFocus(route) {
        this.overviewFocus = false;
        this.dealsFocus = false;
        this.spvFocus = false;
        this.walletFocus = false;
        this.membersFocus = false;
        this.accountFocus = false;
        this.investFocus = false;
        this.apiFocus = false;
        switch (route) {
          case "OverviewDetails":
            this.overviewFocus = true;
            break;
          case "Deals":
            this.dealsFocus = true;
            break;
          case "DealInfo":
            this.dealsFocus = true;
            break;
          case "CreateDeal":
            this.dealsFocus = true;
            break;
          case "CreateAsset":
            this.dealsFocus = true;
            break;
          case "SPVs":
            this.spvFocus = true;
            break;
          case "Invest":
            this.investFocus = true;
            break;
          case "Trade":
            this.investFocus = true;
            break;
          case "TokenTrade":
            this.investFocus = true;
            break;
          case "InvestOrderbook":
            this.investFocus = true;
            break;
          case "Wallet":
            this.walletFocus = true;
            break;
          case "Members":
            this.membersFocus = true;
            break;
          case "Account":
            this.accountFocus = true;
            break;
          case "ApiClient":
            this.apiFocus = true;
            break;
          case "ResetPassword":
            this.accountFocus = true;
            break;

          default:
            break;
        }
      },
    },

    watch: {
      $route(to, from) {
        if (to !== from) {
          this.setFocus(this.$route.name);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #mobile-sidebar {
    @media (max-width: 1280px) {
      .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
      }

      .navbar-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
      }

      .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
      }

      .navbar .menu-items {
        transform: translate(-150%);
        transition: transform 0.5s ease-in-out;
      }

      .navbar .menu-items .faq-btn {
        left: 50%;
        transform: translate(-50%, 0);
      }

      .navbar-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line1 {
        transform: rotate(38deg);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line2 {
        transform: scaleY(0);
      }

      .navbar-container
        input[type="checkbox"]:checked
        ~ .hamburger-lines
        .line3 {
        transform: rotate(-38deg);
      }
    }
  }
</style>
