<template>
  <div>
    <h3 class="tw-font-bold tw-text-lg">Hello, {{ details.name }}</h3>
    <p class="tw-text-sm tw-text-black-light">We’re glad to have you.</p>
  </div>
</template>

<script>
import { getItem } from "@/utils/storage.js";

export default {
  name: "WelcomeText",

  data() {
    return {
      details: {},
    };
  },

  mounted() {
    this.details = getItem("org_details");
  },
};
</script>

<style lang="scss" scoped></style>
